<script setup>
import { onMounted } from "vue";
import Clients from "./Clients.vue";
import PageTitleCard from "@/components/PageTitleCard.vue";
import { setPageTitle } from "../../utils/pageTitle";
import { useRouter } from "vue-router";

const router = useRouter();

onMounted(() => {
  setPageTitle("Clients / Accounts");
});

const addNewClient = () => {
  router.push("/clients/new");
};
</script>

<template>
  <PageTitleCard justifyCard="space-between">
    <template #actions>
      <div class="d-flex-end ml-auto mr-2">
        <v-btn flat class="font-weight-bold" color="primary" @click="addNewClient"
          >New Client</v-btn
        >
      </div>
    </template>
  </PageTitleCard>
  <div id="clients-grid-wrapper">
    <Clients />
  </div>
</template>

<style scoped>
#clients-grid-wrapper {
  height: 100%;
  padding-top: 0.25%;
}
</style>
